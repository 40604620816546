exports.components = {
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-j-join-key-tsx": () => import("./../../../src/pages/j/[joinKey].tsx" /* webpackChunkName: "component---src-pages-j-join-key-tsx" */),
  "component---src-pages-licenses-mdx": () => import("./../../../src/pages/licenses.mdx" /* webpackChunkName: "component---src-pages-licenses-mdx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-r-recovery-token-tsx": () => import("./../../../src/pages/r/[recoveryToken].tsx" /* webpackChunkName: "component---src-pages-r-recovery-token-tsx" */),
  "component---src-pages-s-confirmation-code-tsx": () => import("./../../../src/pages/s/[confirmationCode].tsx" /* webpackChunkName: "component---src-pages-s-confirmation-code-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */)
}

